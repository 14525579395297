<template>
    <!-- <div style="display: flex; justify-content: center; margin-top: 20px">
      <div id="snack_dex2"></div>
    </div> -->
  
    <!-- <div id="videoAd" style="border-top: 1px  solid #000000; padding: 0.8rem 0 0 0; margin-top: 25px; display: flex; justify-content: center">
      <div id="snack_dex2"></div>
  </div>
  <div id="belowVideoAd" style="display: flex; justify-content: center; width: 100%; padding: 0.8rem 0; text-align: center; text-align: -webkit-center; margin: 0 auto 1.5rem; border-bottom: 1px solid #000000; min-height: 50px">
      <div id="snack_dex3"></div>
  </div> -->

 
 
  <div
      style="
        margin: 50px auto 10px auto;
        text-align: center;
        width: 100%;
        overflow: hidden;
        min-height: 250px; 
      "
    >
      <div id="snack_ldb"> 
      </div>
    </div>
  
  
  </template>
  
  <script>
  import { onBeforeMount } from "vue";
  export default {
    setup() {
      onBeforeMount(() => {
        // const script = document.createElement("script");
        // script.id = "snack_ads";
        // script.src =
        //   "https://cdn-header-bidding.snack-media.com/assets/js/snack-loader/3239";
        // script.crossOrigin = "anonymous";
  
        // // Append the script to the head or body
        // document.head.appendChild(script); // You can also use document.body.appendChild(script); if you prefer
  
        // Optionally, you can log a message to confirm that the script has been added
        // console.log('Snack Ads script added to the document.');
      });
  
      return {};
    },
  
    mounted() {
      this.adsenseAddLoad();
    },
  
    methods: {
      adsenseAddLoad() {
        let inlineScript = document.createElement("script");
        inlineScript.type = "text/javascript";
        inlineScript.text = "(adsbygoogle = window.adsbygoogle || []).push({});";
        document.getElementsByTagName("body")[0].appendChild(inlineScript);
      },
    },
  };
  </script>
  
  <style scoped>
  @media only screen and (max-width: 42em){
          #videoAd {
                  min-height: 272px !important;
          }
          #snack_dex3, #snack_mex3 {
                  max-height: 50px !important;
                  max-width: 320px !important;
          }
      .video-container {
              height: 300px !important;
              margin-bottom: 80px !important;
        }
  
  }
  @media only screen and (min-width: 42em){
          #videoAd {
                  min-height: 307px !important;
          }
      #belowVideoAd {
          min-height: 90px;
      }
  }
  </style>