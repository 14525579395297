<template>
    <i class="bi bi-flag-fill flagGiveUp" @click="openBox = true"></i>
  
    <div class="overlay" v-if="openBox === true" @click="openBox = false">
      <div class="modal" @click.stop >
        <B5Button
          @click="openBox = false"
          :text="'x'"
          :class="'modalClose'"
          style="position: absolute; right: 5px; top: 5px"
        />
        <p class="modalText">Confirm you want to give up</p>
        <B5Button class="buttonText" :text="'Yes'" :class="'blue'" @click="$emit('close'); openBox = false" />
        <B5Button class="buttonText" :text="'No'" :class="'blue'" @click="openBox = false" />
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "vue"; 
import B5Button from './B5-Button.vue';
  export default {
    components: { B5Button },
    setup() {
      const openBox = ref(false);
      return { openBox };
    },
  };
  </script>
  
  <style scoped>
  .overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.404);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }
  
  .modal {
    width: 300px;
    margin: auto;
    position: absolute;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    /* transform: translateY(-50%); */
    background-color: whitesmoke;
    padding: 15px;
    border-radius: 20px;
    max-width: 80vw;
    color: black;
  }
  
  .modalText {
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .flagGiveUp {
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 15px;
    font-size: 16px;
    border-radius: 10px;
    border: 2px solid var(--button-color);
    margin: 0 5px;
  }
  
  .flagGiveUp:hover {
    background-color: rgb(100, 82, 48);
  }
  
  .buttonText{
    font-size: 16px;
    font-weight: 600;
    margin: 0 10px;
  }
  
  @media screen and (max-width: 769px){
    .modal {
      top: 40vh;
    }
  
     .buttonText{
      padding: 5px 15px;
      font-size: 16px;
     }
  
     .flagGiveUp {
      font-size: 14px;
     }
  }
  </style>