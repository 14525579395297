<template>
    <div class="blockmodal">
      <div class="overlay" v-on:click="toggleModal"></div>
  
      <div class="modale card" v-on:click="toggleModal">
        <button @click="$emit('close')" class="btn-modale">X</button>
        <h2 style="text-transform: uppercase">PLAYER OPTIONS</h2>
  
        <p style="font-size: 0.9rem; margin-bottom: 1vw">
          You have multiple players to choose from
        </p>
        <div class="footballerList">
          <p
            class="playerParagraph"
            v-for="player in players"
            :key="player"
            @click="$emit('choosePlayer', player.id)"
          >
  
          <img v-if="player.countryFlag" :src="player.countryFlag" style="width: 20px; margin-left: 10px" alt="">
          <img v-if="player.clubLogo" :src="player.clubLogo" style="height: 20px; margin: 0 10px" alt="">
  
            <template v-if="player.nickname">
              {{ player.firstName }} {{ player.lastName }} '{{ player.nickname }}'
            </template>
  
            <template v-else>
              {{ player.firstName }} {{ player.lastName }}
            </template>
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>  
  
 
//   import teams from '@/assets/teams.json'
  export default {
    props: ["players"],
  
     
  };
  </script>
  
  <style scoped>
  button {
    background-color: var(--button-color);
    color: var(--primary-color);
    padding: 0.5vw;
    font-family: inherit;
    /* text-transform: uppercase; */
    font-size: 1.1rem;
    margin: 0 0.5vw;
    border-style: solid;
    border-color: #f1f1f1;
    border-radius: 10px;
  }
  
  .blockmodal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
  
    font-family: "Poppins", sans-serif;
  }
  
  .overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .modale {
    background: #f1f1f1;
    color: #333;
    /* padding: 50px; */
    padding: 20px;
    position: fixed;
  
    border-style: solid;
    border-radius: 20px;
    border-color: #f90d0d00;
  
    text-align: center;
  }
  
  .btn-modale {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: var(--secondary-color);
    color: white;
    border-radius: 10px;
    border-color: transparent;
    width: 40px;
    cursor: pointer;
  }
  
  .footballerList {
    /* padding: 0.5vw 0; */
    max-width: 30vw;
    text-align: left;
    overflow-y: scroll;
    height: 120px;
  }
  
  .playerParagraph {
    font-size: 0.9rem; 
    border-bottom: 2px solid rgba(87, 87, 87, 0.377); 
    cursor: pointer;
    background-color: #d8d8d8;
    margin: 0;
  }
  
  .playerParagraph:hover {
    padding-left: 0.5vw;
    color: var(--primary-color);
    background-color: var(--secondary-color);
  }
  
  @media screen and (max-width: 750px) {
    .modale {
      padding: 5vw;
    }
  
    .footballerList {
      max-width: 70vw;
    }
  }
  </style>