<template>
  <div class="simple-keyboard"></div>
</template>

<script setup>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import { ref, onMounted, defineProps, watch } from "vue"; 

/* eslint-disable */
const emit = defineEmits(["onKeyPress"]);

const keyboard = ref(null);
const props = defineProps(['guessedLetters']);

const guessedLetters = ref(props.guessedLetters)

const onKeyPress = (button) => {
  emit("onKeyPress", button);
};

onMounted(() => {
  keyboard.value = new Keyboard("simple-keyboard", {
    theme: "hg-theme-default hg-theme-ios",
    layout: {
      default: [
        "q w e r t y u i o p",
        "a s d f g h j k l",
        `{enter} z x c v b n m {bksp}`
      ],
    },
    display: {
      "{bksp}": "bksp",
      "{enter}": "enter",
      "{space}": "space",
    },
    buttonTheme: [
      {
        class: "customKeyboard",
        buttons: "- {bksp}",
      },
      {
        class: "hint",
        buttons: guessedLetters.value.hint[0]
      },
      {
        class: "wrong",
        buttons: guessedLetters.value.wrong[0]
      },
      {
        class: "correct",
        buttons: guessedLetters.value.correct[0]
      }
    ],
    layoutName: "default",
    onKeyPress: onKeyPress,
  });

  watch(guessedLetters.value, (newValue, oldValue) => {
    const newButtonTheme = [
      {
        class: "customKeyboard",
        buttons: "- {bksp}",
      },
      {
        class: "hint",
        buttons: guessedLetters.value.hint[0]
      },
      {
        class: "wrong",
        buttons: guessedLetters.value.wrong[0]
      },
      {
        class: "correct",
        buttons: guessedLetters.value.correct[0]
      }
    ];
    
    // Apply the updated button theme
    keyboard.value.options.buttonTheme = newButtonTheme;
    keyboard.value.render();
  });
});
</script>

<style>
.hg-theme-default {
  background-color: var(--primary-color);
  font-family: inherit;
  font-weight: 400;
  color: black;
}

.hg-button:hover {
  font-weight: 600;
  background-color: rgb(183, 183, 183);
}

.customKeyboard {
  /* background: rgb(199, 199, 199) !important; */
  /* background-color: red; */
}

.correct{
  background: #79b851 !important;
  background: mediumseagreen !important;
  color: white;
}

.hint{
  background: var(--button-color) !important;
  /* background: var(--button-color); */
  color: white;
}

.wrong{
  background: grey !important;
  color: white;
}

.hg-button-space {
  width: 65%;
}
</style>