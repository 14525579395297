<template> 
    <div
      class="player-card-box"
      v-bind:class="{ flipme: player.player != null }"
    >
      <div class="player-card card-front">
        {{ player.position }}
      </div>
      <div class="player-card card-back" v-if="player.player"> 
        <img :src="player.player.logo" class="playerLogo" alt="" />
        <span class="playerFirstName">
          {{ player.player.firstName }}
        </span>
        <span class="playerLastName" >
          {{ player.player.lastName }}
        </span>
         
      </div>
    </div>
  </template>
  
  <script>
 
  
  export default {
    props: ["player"],
    setup() {
      
       
    },
  };
  </script>
  
  <style scoped>
  .playerLogo {
    /* max-height: 15px; */
    /* max-height: 60px; */
    /* margin-bottom: 6px; */
    margin-bottom: 10px;
    height: 60px;
    max-width: 80px;
  }
  
  .playerLogoClubsMode{
    max-height: 25px;
    margin-bottom: 6px;
  }
  
  .playerFirstName {
    font-size: 12px;
    line-height: 12px;
    display: none;
  }
  
  .playerLastName {
    text-transform: uppercase;
    font-weight: 400;
    line-height: 20px;
    
    font-size: 20px;
    color: var(--text-color)
  }
  
  .player-card-box {
    transition: transform 1s;
    transform-style: preserve-3d;
  
    width: 60px;
    height: 60px;
  
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .player-card {
    color: white;
  
    width: 100%;
    height: 100%;
    /* width: 60px; */
    /* height: 60px; */
    display: flex;
    justify-content: center;
    align-items: center;
  
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
  
    border: 2px solid var(--button-color);
    border-radius: 50px;
    color: var(--button-color);
    /* background-color: var(--primary-color); */
  }
  
  .card-front {
    /* height: 50%; */
    font-size: 30px;
  }
  
  .card-back {
    /* background-color: var(--button-color); */
    color: var(--primary-color);
    transform: rotateY(180deg);
  
    display: flex;
    flex-direction: column;
    border: none;
    /* width: unset; */

  }
  
  .flipme {
    transform: rotateY(180deg);
  }
  
  @media screen and (max-height: 900px) {
    .player-card-box {
      margin: 5px 0; 
    }
  }
  
  @media screen and (max-width: 768px) {
    .playerFirstName {
      display: none;
    }
  
    .playerLastName {
      /* font-size: 12px; */
      font-size: 10px;
      font-weight: 500;
    }
    .player-card-box {
      /* height: 20px;
      height: 40px;
      width: 100px;
      width: 120px;
      max-width: 30vw; */

      height: 50px;
      width: 50px; 
      margin: 5px 0; 

    }
    .player-card {
      /* flex-direction: row; */
      align-items: center;
      border-radius: 30px;
      border-width: 1.5px; 
      font-size: 20px; 
      /* background-color: grey; */
    }
    .playerLogo {
      /* max-height: 15px;
      max-height: 20px; */
      margin: auto 0;
      height: 45px;
      margin-bottom: 5px;
      /* margin-right: 5px; */
    }
  
    .card-front {
      height: 100%;
    }

    .playerLastName{ 
      font-size: 18px;
    }
  
    
  }
  @media screen and (min-width: 769px) {
    .playerFirstName{
      font-size: 13px;
    }
    .playerLastName{
    }
  
    .player-card-box{
      /* width: 150px; */
    }
  }
  </style>