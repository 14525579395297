<template>
  <DesktopSideAd />
  <div class="home">
    <p class="mainText">Select the game you want to play:</p>

    <div class="games">
      <GameThumbnail v-for="game in games" :key="game" :game="game" />
    </div>

    <p class="mainText">More games coming soon</p>
  </div>

  <LeaderBoardAd />
</template>

<script>
import GameThumbnail from "@/components/GameThumbnail.vue";
import LeaderBoardAd from "@/components/ads/LeaderBoardAd.vue";
import DesktopSideAd from "@/components/ads/DesktopSideAd.vue";
export default {
  name: "HomeView",
  components: { GameThumbnail, LeaderBoardAd, DesktopSideAd },
  setup() {
    document.addEventListener("DOMContentLoaded", () => {
      document.getElementById("infoButtonCircle").style.display = "none";
      document.getElementById("statsButtonCircle").style.display = "none";
    });

    const games = [
      {
        routerLink: "/basketball5-wordle",
        firstName: "Basketball5", 
        secondName: "Wordle", 
        fullName: "Basketball5 Wordle",
        important: "NEW",
        image: "https://i.ibb.co/zZ0tDd7/2-CACS-1-b.webp",
        image2: "https://i.ibb.co/58fzk8G/fgh-2.webp",
        string: "b5-wordle"
      },
      {
        routerLink: "/basketball-5",
        firstName: "Basketball5",
        image: "https://i.ibb.co/wpDZRgM/5ASC.png",
        image2: "https://i.ibb.co/yfky1cH/bas.png",
        fullName: "Basketball5",
        update: "UPDATE",
        string: "b5-stats"
      },
    ];
    return { games };
  },
};
</script>

<style scoped>
.mainText {
  margin-top: 30px;
  font-size: 25px;
  font-weight: 200;
}

.gameThumbnail {
  background-color: var(--primary-color);
  height: 80px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border: 2px solid var(--secondary-color);
  margin: 10px auto;
}

.gameThumbnail:hover {
  background-color: var(--secondary-color);
}

.games {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;

  flex-direction: row;
  flex-wrap: wrap;
  max-width: 700px;
  justify-content: space-evenly;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .games {
    flex-direction: column;
    align-items: center;

    flex-wrap: unset;
    max-width: unset;
    justify-content: unset;
    margin: unset;
  }

  .mainText {
    font-size: 20px;
    margin-top: 20px;
  }
}
</style>