<template>
    <!-- <p>osokso {{ guess }}</p> -->
  
    <div style="display: flex; justify-content: center" v-if="guess">
      <div
        v-for="letter in guess"
        :key="letter"
        class="wordle-box grey"
        :class="letter.result"
      >
        {{ letter.lt }}
      </div>
    </div>
  
    <div style="display: flex; justify-content: center" v-else-if="currentGuess">
      <div v-for="n in length" :key="n" class="wordle-box current">
        {{ currentGuess[n - 1] }}
      </div>
    </div>
  
    <div style="display: flex; justify-content: center" v-else>
      <div v-for="number in length" :key="number" class="wordle-box"></div>
    </div>
    <!-- <div class="animate__animated animate__wobble animate__delay-0s">Example</div> -->
  </template>
  
  <script>
  export default {
    props: ["guess", "currentGuess", "length"],
  };
  </script>
  
  <style scoped>
  * {
    color: crimson;
  }
  
  .wordle-box {
    height: 55px;
    width: 55px;
    line-height: 55px;
    margin: 5px;
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
    color: black;
    color: whitesmoke;
    font-weight: 600;
    background-color: white;
    border-radius: 5px;
    /* border: 2px solid var(--secondary-color) */
    background: #191a24;
    border: 2px solid #414458;
  }
  .grey {
    background-color: rgb(163, 156, 156);
    background-color: var(--field-color);
  
    animation: flipInX;
    animation-duration: 1s;
  }
  
  .current {
    border: 2px solid #797e9c;
    /* background: #313243; */
  }
  
  .green {
    /* background-color: rgb(47, 195, 47); */
    /* background-color: #79b851; */
    background-color: mediumseagreen;
    border: 2px solid mediumseagreen;
  }
  
  .yellow {
    /* background-color: rgb(241, 175, 33); */
    background-color: var(--button-color);
    border: 2px solid var(--button-color);
  }
  </style>