<template>
  <div class="formation">
    <div class="positions guards">
      <B5PlayerCard :player="lineup[0]" />
      <B5PlayerCard :player="lineup[1]" />
    </div>

    <div class="positions center">
      <B5PlayerCard :player="lineup[2]" />
    </div>

    <div class="positions forwards">
      <B5PlayerCard :player="lineup[3]" />
      <B5PlayerCard :player="lineup[4]" />
    </div>

    <!-- <div class="rating">
      <span>Rating: <br /><span class="ratingText">{{ currentRating }}</span></span> 
    </div> -->

    <div class="ratingMobile">
      <span>{{ currentRating }}</span>
    </div>
  </div>
</template>

<script>
import B5PlayerCard from "./B5-PlayerCard.vue";
export default {
  components: { B5PlayerCard },
  props: ["lineup", "currentRating"],
};
</script>

<style scoped>
.formation {
  /* background-color: #fff8e4; */
  width: 700px;
  margin: 20px auto;

  border: 2px solid var(--button-color);
  border-radius: 20px;

  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}

.rating {
  position: absolute;
  top: 10px;
  right: 10px;
  /* border: 1px solid red; */
  border-radius: 20px;
  padding: 10px;
  background-color: rgba(185, 210, 255, 0.37);
  /* color: var(--button-color)   */
}
.guards {
  display: flex;
  justify-content: space-evenly;
}

.center {
  display: flex;
  justify-content: center;
}
.forwards {
  display: flex;
  justify-content: space-around;
}

.ratingText {
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  color: var(--button-color);
}

.ratingMobile {
    position: absolute;
    top: calc(100% + 20px);
    right: 0px;
    /* font-size: 15px;
    padding: 5px 10px;
    font-weight: 300; */
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid;
    border-color: var(--button-color); 
    background-color: var(--button-color);
    text-align: center;
    line-height: 50px;
    font-size: 30px;
    color: var(--primary-color);
    font-weight: 400;
  }

@media screen and (max-width: 768px) {
  .formation {
    height: 250px;
    min-height: unset;
    /* max-height: 30vh; */
    max-width: 90vw;
  }

  .ratingMobile {
    top: calc(100% + 20px);
 
  }

  .ratingText {
    /* font-size: 35px;
    line-height: 25px; */
  }
}
</style>