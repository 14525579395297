<template>
  <!-- <router-link :to="game.routerLink">
  </router-link> -->

  <a
    :href="game.routerLink"
    class="gameThumbnailLink desktop animate__animated animate__fadeIn"
    style="position: relative"
    :target="game.type ? '_blank' : ''"
  >
    <div class="streaks" v-if="streak > 0">
      {{ streak + "🔥" }}
    </div>
    <div v-if="game.important" class="tag">{{ game.important }}</div>
    <!-- <div   class="tag">{{game.fullName}}</div> -->
    <div v-if="game.update" class="tag update">{{ game.update }}</div>
    <div class="cardDiv">
      <img :src="game.image2" alt="" style="max-width: 100%" />
      <div class="text">
        <p>Play</p>
        <!-- <p style="font-size: 15px;">{{ game.fullName }}</p> -->
        <!-- <p class="gameTitle">{{ game.fullName }} </p> -->
        <p class="gameTitle">{{ game.fullName }}</p>
      </div>
    </div>
  </a>

  <a
    :href="game.routerLink"
    class="gameThumbnailLink mobile"
    :target="game.type ? '_blank' : ''"
  >
    <div class="gameBox" :class="{ specialBox: game.type }">
      <div class="streaksMobile" v-if="streak > 0">
        {{ streak + "🔥" }}
      </div>
      <div class="gameBoxText">
        <p class="mainName">{{ game.firstName }}</p>
        <p class="secondName">{{ game.secondName }}</p>
      </div>
      <p class="important">{{ game.important }}</p>
      <p class="important update">{{ game.update }}</p>

      <!-- <img :src="game.img" alt="" class="gameThumbnailImage" /> -->
      <img :src="game.image" alt="" class="futbol11image" />
    </div>
  </a>
</template>

<script>
import { ref } from "vue";
export default {
  props: ["game"],
  setup(props) {
    /* eslint-disable */
    const streak = ref(0);

    let string = props.game.string;

    if (string && JSON.parse(window.localStorage.getItem(string))) {
      if (string == "guessWhoStats") {
        streak.value = JSON.parse(
          window.localStorage.getItem(string)
        ).currentStreak;
      } else {
        streak.value = JSON.parse(
          window.localStorage.getItem(string)
        ).stats.currentStreak;
      }
    }

    return { streak };
  },
};
</script>

<style scoped>
p {
  margin: 0;
}

.streaksMobile {
  position: absolute;
  z-index: 3;
  left: -10px;
  top: -5px;
  background-color: rgb(38 30 75);
  border: 2px solid rgb(169 156 231 / 77%);
  background-color: rgb(221 221 221);
  border: 2px solid rgb(0 0 0 / 72%);
  color: black;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 5px;
  font-weight: 700;
}

.streaks {
  position: absolute;
  z-index: 3;
  right: -5px;
  background-color: rgb(56, 44, 115);
  border: 2px solid rgb(169 156 231 / 32%);
  background-color: rgb(221 221 221);
  border: 2px solid rgb(0 0 0 / 72%);
  color: black;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 5px;
  font-weight: 700;
}

.cardDiv {
  height: 220px;
  width: 150px;
  background-color: #0f2743;
  margin: 10px 10px;
  margin-bottom: 30px;
  padding: 0;
  /* border: 1px solid grey; */
  box-shadow: inset 0 0 0 1px rgba(255, 234, 221, 0.1);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.cardDiv .text {
  position: absolute;
  bottom: 0;
  background-color: var(--bluePop-color);
  width: 100%;
  font-size: 22px;
  text-transform: uppercase;
  /* font-style: italic; */
  font-weight: 600;
}

.cardDiv img {
  max-width: 90% !important;
  /* margin-top: 10px; */
}

.desktop .tag {
  position: absolute;
  background-color: var(--button-color);
  background-color: mediumspringgreen;
  /* top: 10px; */
  /* left: 10px; */
  top: 0px;
  left: -5px;
  z-index: 2;
  font-size: 16px;
  padding: 3px 7px;
  border-radius: 10px;
  color: black;
  font-weight: 600;
}

.desktop .update {
  background-color: var(--button-color);
}

.cardDiv:hover {
  box-shadow: 0 0 0 2px var(--button-color);
  /* background-color: var(--primary-color) */
}

.cardDiv .text {
  transition: background-color 0.25s linear, color 0.3s ease;
}
.cardDiv:hover .text {
  background-color: var(--button-color);
  color: black;
  -webkit-animation: shake 1s;
  animation: shake 1s;
}
.cardDiv:hover .text p {
  color: #031825;
}

.gameTitle {
  /* margin-top: -20px; 
  margin-bottom: 20px;  */
  font-size: 14px;
  text-transform: capitalize;
  /* position: absolute;
  bottom: 35px; */
  font-weight: 400;
}
.cardDiv:hover .gameTitle {
  color: var(--bluePop-color); /* Change color to your desired hover color */
}
.futbol11image {
  position: absolute;
  height: 100%;
  right: 0;
}

.gameBox {
  /* background-color: rgb(57, 57, 57); */
  /* border: 1px solid whitesmoke; */
  height: 100px;
  width: 300px;
  margin-bottom: 15px;
  position: relative;
  background-color: #0a2f45;
  /* box-shadow: inset 0 -51px 27px -16px red */
}

.specialBox {
  background-color: var(--button-color);
  background-color: #c9ac55;
}

.specialBox p {
  color: black;
}
.gameBoxText {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  bottom: 5px;

  /* align-items: center; */
  z-index: 1;
}

.mainName {
  font-weight: 500;
  /* font-size: 1.5rem; */
  font-size: 24px;
  text-transform: uppercase;
  font-style: italic;
  /* color: var(--button-color); */
  padding-left: 5px;
  /* text-shadow: 0 0 16px black; */
}

.secondName {
  font-size: 16px;
  text-transform: uppercase;
  font-style: italic;
  padding-left: 5px;
  /* text-shadow: 0 0 16px black; */
}

.important {
  position: absolute;
  top: 0;
  left: 60px;
  background-color: red;
  background-color: rgb(34, 210, 34);
  color: black;
  padding: 0 5px;
  z-index: 1;
}

.update {
  background-color: var(--button-color);
}

.gameThumbnailLink {
  text-decoration: none;
}

.gameThumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 20%;
}

.gameThumbnailLink:hover .gameThumbnailImage {
  /* opacity: 35%; */
}

.gameBox:hover {
  /* border: 1px solid gold; */
  /* height: 120px; */
  background-color: #f2b705;
  /* background-color: #408ab5 */
}

.gameBox:hover p {
  /* color: #0096a9; */
  color: #031825;
}

.gameBox:hover .mainName {
  /* color: gold; */
}

* {
  color: white;
}

.mainDiv {
  height: 250px;
  border-bottom: 1px solid pink;
  position: relative;
  width: 400px;
  margin: 20px;
  overflow: hidden;
  border: 2px solid var(--secondary-color);
  border-radius: 20px;
  max-width: 40vw;
}

.mainDiv:hover #overlay {
  opacity: 35%;
}

.gameThumbnail {
  /* height: 150px; */
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gameThumbnailText {
  position: absolute;
  bottom: 10%;
  background-color: black;
  width: 100%;
  /* border: 1px solid gold; */
  border-top: 2px double var(--button-color);
  border-bottom: 2px double var(--button-color);
  font-size: 20px;
  /* width: calc(100% + 2px); */
}

#overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 70%;
  /* opacity: 50%; */
}

.mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile {
    display: unset;
  }

  .desktop {
    display: none;
  }
  .mainDiv {
    border: 1px solid var(--secondary-color);
    height: 150px;
    width: 200px;
    box-shadow: 0 0 10px var(--secondary-color);
    box-shadow: unset;
    max-width: unset;
  }

  .gameThumbnailText {
    font-size: unset;
    font-size: 14.5px;
  }

  .mainName {
    font-size: 20px;
  }
}
</style>