<template>
  <Header />
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />

  <Footer />
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Gasoek+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'animate.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; 
  color: var(--text-color);
  font-family: 'Kanit', sans-serif;
  min-height: 100vh;
}

:root {
  --primary-color: #f7ecce;
  --secondary-color: #fdd871; 
  --third-color: #4365c5;
  --text-color: black;
  

  /* --primary-color: #ebfaff;
  --secondary-color: #17408B;
  --secondary-color-opacity: #17408b52;
  --third-color: #C9082A;
  --text-color: white;  */

  --primary-color: #001d3d;
  --primary-color: #011327;
  --secondary-color: #003566;
  --secondary-color-opacity: #17408b52;
  --button-color: #ffc300;
  --bluePop-color: #1478ca;
  --text-color:white;
}

body {
  margin: 0px;
  background-color: var(--primary-color);
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>

import Header from "./components/HeaderComponent.vue";
import Footer from "./components/FooterComponent.vue";
export default {
  components: { Header, Footer },
};
</script>