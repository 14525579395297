<template>
    <div class="blockmodal">
      <div class="overlay" v-on:click="toggleModal"></div>
  
      <div class="modale card" v-on:click="toggleModal">
        <!-- <button @click="$emit('close')" class="btn-modale">X</button> -->
        <B5Button @click="$emit('close')" :text="'x'" :class="'modalClose'" style="position: absolute; right: 5px; top: 5px" />
  
        <h2 style="text-transform: uppercase">Statistics</h2>
  
        <div id="statsFlex">
          <div class="statSection">
            <h1>{{ basketball5localStorage.stats.played }}</h1>
            <p>Played</p>
          </div>
          <div class="statSection">
            <h1>{{ basketball5localStorage.stats.wins }}</h1>
            <p>Wins</p>
          </div>
          <div class="statSection">
            <h1>
              {{
                Math.round(
                  (basketball5localStorage.stats.wins /
                    basketball5localStorage.stats.played) *
                    100
                )
              }}
            </h1>
            <p>%</p>
          </div>
          <div class="statSection">
            <h1>{{ basketball5localStorage.stats.currentStreak }}</h1>
            <p>Streak</p>
          </div>
          <div class="statSection">
            <h1>{{ basketball5localStorage.stats.maxStreak }}</h1>
            <p>Max Streak</p>
          </div>
        </div>
  
        <div
          style="
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
          "
        >
          <div
            v-for="(playersCorrect, index) in basketball5localStorage.distribution"
            :key="playersCorrect"
          >
            <div
              style="
                height: 25vh;
                display: flex;
                justify-content: center;
                align-items: flex-end;
              "
            >
              <div
                style="
                  background-color: var(--secondary-color);
                  width: 80%;
                  color: white;
                "
                v-if="playersCorrect > 0"
                :style="{
                  height:
                    (playersCorrect /
                      Math.max(...basketball5localStorage.distribution)) *
                      100 +
                    '%',
                }"
              >
                {{ playersCorrect }}
              </div>
            </div>
            <div>{{ index + 1 }}</div>
          </div>
        </div>
  
        <p style="font-size: 0.8rem; font-weight: 600">©Zgjim G.</p>
  
        <p style="font-size: 0.8rem; font-weight: 500">
          Contact: thebasketball5game@gmail.com
        </p>
  
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0;
          "
        >
          <a
            :href="'https://twitter.com/intent/tweet?text=' + para"
            target="_blank"
          >
            <B5Button :text="'Share'" @click="copyDailyStats" />
            <a href="https://www.instagram.com/basketball5_official/" target="_blank">
            <B5Button :text="'Follow our Instagram'"   />
          </a>
          </a> 
        </div>
      </div>
    </div>
  </template>
        
        <script>
  import B5Button from '@/components/B5-Button.vue';
  import { ref } from "vue";
  export default {
    components: {
      B5Button, 
    },
    setup() {
      const basketball5localStorage = ref(null);
      const basketball5localStorageDaily = ref(null);
  
      const hashtags = ref("%23Basketball5 %23Basketball5Wordle");
  
      const moment = require("moment");
  
      basketball5localStorage.value = JSON.parse(
        window.localStorage.getItem("b5-wordle")
      );
  
      basketball5localStorageDaily.value = JSON.parse(
        window.localStorage.getItem("b5-wordle-daily")
      );
  
      let guesses = basketball5localStorageDaily.value.guesses;
  
      let blocks = ref("");
  
      for (let i = 0; i < guesses.length; i++) {
        let player = guesses[i];
        for (let j = 0; j < player.length; j++) {
          if (player[j].result == "green") {
            blocks.value += "🟩";
          } else if (player[j].result == "yellow") {
            blocks.value += "🟨";
          } else {
            blocks.value += "⬜";
          }
        }
        blocks.value += "%0A";
      }
   
      let result = ' - ' + guesses.length + '/' + 6
  
      let startDate = "2024-07-22";
      const endDate = moment().date(Number);
  
      const diffInDays = moment(endDate).diff(moment(startDate), "days");
  
      const para = ref("");
  
      const copyDailyStats = () => {
        para.value =
          hashtags.value +
          " Day " +
          diffInDays +
          result +
          "%0A%0A" +
          blocks.value +
          "%0A" +
          "https://basketball-5.com/";
      };
  
      return {
        copyDailyStats,
        para,
        basketball5localStorage,
      };
    },
  };
  </script>
        
        <style scoped>
  #underButtonParagraph {
    background-color: rgb(156, 238, 185);
  
    background-color: rgb(255, 189, 66);
    margin-bottom: 1vw;
  }
  
  .twitterLink {
    text-decoration: none;
    color: rgb(43, 43, 43);
    /* text-transform: uppercase; */
    background-color: rgb(13, 208, 247);
    /* background-color: orange; */
  }
  
  #updates {
    background-color: rgb(56, 44, 115);
    color: rgb(251, 247, 238);
    max-width: 30vw;
  }
  
  #spanUpdate {
    font-size: 0.8rem;
    border-bottom: 2px solid rgb(21, 15, 52);
    margin-bottom: 1px;
  }
  
  .socialLink {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: black;
  }
  
  .socialLink:hover {
    color: var(--button-color);
  }
  
  .bi-twitter {
    color: rgb(29, 180, 222);
    /* font-size: 1.5rem; */
  }
  
  button {
    background-color: var(--button-color);
    color: var(--primary-color);
    padding: 0.5vw;
    font-family: inherit;
    /* text-transform: uppercase; */
    font-size: 1.1rem;
    margin: 0 0.5vw;
    border-style: solid;
    border-color: #f1f1f1;
    border-radius: 10px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: var(--darkerButton-color);
  }
  
  .chartLine {
    margin: 0.5vw 0;
    display: flex;
    flex-direction: row;
  }
  .line {
    background-color: var(--secondary-color);
    color: white;
    /* max-width: 80%; */
  }
  
  .statSection {
    /* background-color: blue; */
    padding: 0.5vw 0vw;
    /* border-style: solid; */
    width: 5vw;
  }
  
  .blockmodal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
  
    font-family: "Poppins", sans-serif;
  }
  
  .overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .modale {
    background: #f1f1f1;
    color: #333;
    /* padding: 50px; */
    padding: 20px;
  
    position: fixed;
  
    border-style: solid;
    border-radius: 20px;
    border-color: #f90d0d00;
  
    text-align: center;
  
    max-width: 30vw;
    z-index: 10;
  }
  
  .btn-modale {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: var(--secondary-color);
    color: white;
    border-radius: 10px;
    border-color: transparent;
    width: 40px;
    cursor: pointer;
  }
  .btn-modale:hover {
    background-color: var(--secondary-color);
  }
  
  #statsFlex {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  #copiedClipboardText {
    background-color: var(--button-color);
    background-color: white;
    border: 2px solid var(--button-color);
    border-radius: 10px;
    font-weight: 500;
    width: 70%;
    position: absolute;
    left: 0;
    right: 0;
    /* top: 0; */
    margin: -40px auto auto auto;
  
    /* margin: 0 auto; */
  }
  
  .displayStats {
    background-color: unset;
    border: 2px solid var(--secondary-color);
    font-size: 0.7rem;
    padding: 0.2vw;
    color: black;
    margin: 1vw 0.5vw;
  }
  
  .displayStats:hover {
    background-color: rgb(190, 190, 190);
  }
  
  .activeStats {
    background-color: var(--secondary-color);
    /* background-color: blue; */
    color: white;
  }
  
  @media screen and (max-width: 750px) {
    .statSection {
      width: 20%;
      /* background-color: orange; */
    }
  
    .modale {
      padding: 5vw;
    }
  
    .card {
      max-width: 70vw;
    }
  
    .paragraphGameOver {
      font-size: 0.8rem;
      margin: 1vw 0;
    }
  
    .shareButton {
      padding: 1vw;
    }
    #updates {
      max-width: 70vw;
    }
  
    .displayStats {
      font-size: 0.6rem;
    }
  }
  </style>