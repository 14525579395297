<template> 
    <button :class="'searchButton ' + (additionalClass ? additionalClass : '')">
      {{ text }}
    </button>
  </template>
  
  <script>
  export default {
    props: ["text", "additionalClass"],
  };
  </script>
  
  <style scoped>
  .searchButton {
    padding: 5px 10px;
    font-family: inherit;
    font-size: 16px;
    border: 2px solid var(--button-color);
    border-radius: 10px;
    background-color: var(--button-color);
    color: var(--primary-color);
    cursor: pointer;
    margin: 0 5px;
    outline: none;
  }
  
  .blue {
    background-color: #49bce3;
    border: 2px solid #49bce3; 
    font-size: 14px;
  }
  
  .searchButton:hover {
  }

  .modalClose{
  border-radius: 30px;
  text-transform: uppercase;
  height: 40px;
  width: 40px;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  background-color: crimson;
  border: unset;
  color: white;
}

.modalClose:hover{
  background-color: rgb(112, 9, 29);
  background-color: crimson; 
  /* border: 3px solid black;
  color: black; */
  border: 3px solid rgb(143, 6, 33); 
}
  </style>