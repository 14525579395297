<template>
    <!-- <input type="text"> -->
    <input type="text" :value="modelValue" class="searchPlayerInput" placeholder="Type the player here"
      @input="$emit('update:modelValue', $event.target.value)" >
  </template>
  
  <script>
export default {
    props: ['modelValue'],
    emits: ['update:modelValue']
}

  </script>
  
  <style scoped>
    .searchPlayerInput{
      padding: 5px 10px;
      font-family: inherit;
      font-size: 16px;
      border: 2px solid var(--button-color);
      border-radius: 10px;
      /* background-color: var(--secondary-color); */
      /* color: var(--text-color); */
      margin: 0 5px;
  
      outline: none;
    }
  
  
    @media screen and (max-width: 768px) {
      .searchPlayerInput{
        max-width: 50%;
      }
    }
  </style>