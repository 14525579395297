<template>
  <div class="background">
    <div class="modal">
      <div class="top">
        <p>HOW TO PLAY</p>
        <p>{{rules ? rules.title : 'Basketball5'}} Rules</p>
        <B5Button
          @click="$emit('close')"
          :text="'x'"
          :class="'modalClose'"
          class="button"
        />
      </div>

      <div class="text"> 
        <p v-html="rules.text"></p>
      </div>
    </div>
  </div>
</template>
  
  <script>
import B5Button from "./B5-Button.vue";
export default { components: { B5Button },
props: ['rules'] };
</script>
  
  <style scoped>
.background {
  background-color: rgba(0, 0, 0, 0.404);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.modal {
  width: 600px;
  margin: auto;
  position: absolute;
  top: 20vh;
  left: 50%;
  transform: translateX(-50%);
  background-color: whitesmoke;
  padding: 15px;
  border-radius: 20px;
  max-width: 80vw;
  color: black;
  text-align: left;
}

.modal .top {
  /* position: relative; */
}

.modal .top p {
  font-size: 26px;
  font-weight: 500;
  margin: 10px 0;
}

.modal .top .button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal .text {
  text-align: left;
}

@media screen and (max-width: 769px) {
  .modal {
    top: 10vh;
  }
  .modal .text {
    font-size: 13px;
  }

  .modal .top {
    text-align: left;
  }

  .modal .top p {
    font-size: 18px;
  }
}
</style>