<template>
    <div class="footer">
      <div class="left-side">
        <img src="https://i.ibb.co/7VpdkLT/B5-Copy.png" class="b5-icon" alt="" />
        <p>
          Basketball5 is a website that offers daily basketball / NBA games for
          players to enjoy. <br />
          <br />Basketball5 uses images of basketball players and teams for
          identification purposes, but does not possess any ownership rights over
          them.
        </p>
      </div>
      <div class="quick-links">
        <h3>Quick Links</h3>
        <a href="/">Home</a> 
        <a href="/basketball-5">Basketball5</a>   
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/about">About</a>
        <!-- <a href="https://futbol-11.com/">Futbol11</a>    -->
      </div>
      <div class="right-side">
        <h3>Social Media Links</h3>
        <div class="social-icons">
          <a href="https://www.instagram.com/basketball5_official/" target="_blank"
            ><i class="bi bi-instagram"></i
          ></a>
          <a href="https://twitter.com/Basketball5game" target="_blank"
            ><i class="bi bi-twitter"></i
          ></a>
          <a href="https://www.tiktok.com/@basketball5daily" target="_blank"
            ><i class="bi bi-tiktok"></i
          ></a>
        </div>
        <p style="margin: 5px 0"><strong style="color: var(--button-color)">Email:</strong> thebasketball5game@gmail.com</p>
      </div>
    </div>
    <div id="snack_dex1"></div>
  </template>
  
  <script>
  import { onBeforeMount } from "vue";
  export default {
    setup() {
      onBeforeMount(() => {
        const script = document.createElement("script");
        script.id = "snack_ads";
        script.src =
          "https://cdn-header-bidding.snack-media.com/assets/js/snack-loader/3456";
        script.crossOrigin = "anonymous";
        document.head.appendChild(script);
        
        const loadExternalScript = () => {
          const script = document.createElement("script");
          script.src = `${document.location.protocol}//tag.wknd.ai/6876/i.js`;
          script.async = true;
          document.head.appendChild(script);
        };
        loadExternalScript();
  
        // const script2 = document.createElement("script");
        // script2.type = "text/javascript";
        // script2.language = "javascript";
        // script2.src =
        //   "https://live.primis.tech/live/liveView.php?s=116030&schain=1.0,1!snack-media.com,SNM_3239,1"; 
        // document.head.appendChild(script2);
      });
  
      return {};
    },
  
    mounted() {
      this.adsenseAddLoad();
    },
  
    methods: {
      adsenseAddLoad() {
        let inlineScript = document.createElement("script");
        inlineScript.type = "text/javascript";
        inlineScript.text = "(adsbygoogle = window.adsbygoogle || []).push({});";
        document.getElementsByTagName("body")[0].appendChild(inlineScript);
      },
    },
  };
  </script>
  
  <style scoped>
  .footer {
    background-color: var(--secondary-color); 
    border-top: 2px solid var(--button-color);
    position: sticky;
    top: 100%;
    padding: 30px 10px;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
  }
  
  .footer div {
    width: 25%;
  }
  
  .left-side {
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .left-side p {
    font-size: 0.9rem;
  }
  
  .b5-icon {
    height: 50px;
    margin-bottom: 20px;
  }
  
  .quick-links {
    display: flex;
    flex-direction: column;
  }
  
  .quick-links h3 {
    color: var(--button-color);
    margin-bottom: 20px;
  }
  
  .quick-links a {
    text-decoration: none;
    color: white;
  }
  
  .quick-links a:hover {
    color: var(--button-color);
  }
  
  .right-side {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  .right-side h3 {
    color: var(--button-color);
  }
  
  .social-icons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    width: unset !important;
  }
  
  .social-icons i {
    margin: 5px;
    font-size: 30px;
    color: white;
  }
  
  .social-icons i:hover {
    color: var(--button-color);
  }
  
  @media screen and (max-width: 768px) {
    .footer {
      flex-direction: column;
      /* margin-top: 100px; */
      padding: 30px 30px;
    }
  
    .footer div {
      width: unset;
    }
  
    .quick-links {
      margin: 25px 0;
    }
  }
  </style>