<template>
  <DesktopSideAd />

  <div style="margin-top: 30px">
    <span v-for="n in 6" :key="n">
      <span v-if="B5WordleDaily.guesses[n - 1]">
        <WordRow :guess="B5WordleDaily.guesses[n - 1]" />
      </span>

      <span v-else>
        <span v-if="B5WordleDaily.currentIndex == n">
          <WordRow
            :currentGuess="currentGuessedWord"
            :length="B5WordleDaily.selectedPlayer.length"
          />
        </span>
        <span v-else>
          <WordRow :length="B5WordleDaily.selectedPlayer.length" />
        </span>
      </span>
    </span>
  </div>
  <div
    style="
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text-color);
      font-size: 18px;
    "
  >
    <p v-if="B5WordleDaily.gameStatus == 'playing'" style="color: white">
      {{ message }}
    </p>

    <div
      v-else
      class="animate__animated animate__bounceIn"
      style="font-weight: 200"
    >
      <p v-if="B5WordleDaily.gameStatus == 'lost'">
        You lost.<br />The correct player is
        <span style="color: crimson; font-weight: 600">{{
          B5WordleDaily.selectedPlayer
        }}</span>
      </p>
      <span v-if="B5WordleDaily.gameStatus == 'won'"
        >You won.<br />The correct player is
        <span style="color: mediumseagreen; font-weight: 600">{{
          B5WordleDaily.selectedPlayer
        }}</span
        ></span
      >
    </div>
  </div>
  <Keyboard
    @onKeyPress="handleInput"
    :guessedLetters="B5WordleDaily.guessedLetters"
    style="max-width: 400px; margin: auto"
  />

  <B5Info :rules="rules" v-if="showInfo === true" @close="showInfo = false" />

  <B5Stats
    :league="'wordle'"
    v-if="showStats === true"
    @close="showStats = false"
  />

  <LeaderBoardAd />
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import WordRow from "./components/WordRow.vue";
import words from "./assets/words.json";
import Keyboard from "@/components/SimpleKeyboard.vue";
import B5Info from "@/components/B5-InfoUpdate.vue";
// import B5Stats from '@/components/B5-Stats.vue';
import B5Stats from './components/B5WordleStats.vue';
import LeaderBoardAd from "@/components/ads/LeaderBoardAd.vue";
import DesktopSideAd from "@/components/ads/DesktopSideAd.vue";

export default {
  components: {
    WordRow,
    Keyboard,
    B5Info,
    B5Stats,
    LeaderBoardAd,
    DesktopSideAd
  },

  setup() {
    const message = ref("");
    const showInfo = ref(false);
    const showStats = ref(false);

    const rules = ref({
      title: "Basketball5 Wordle",
      text: `Guess the Basketball Wordle in 6 tries or less! Your goal is to find the football player from any era. Start by guessing a player.<br><br>
          Feedback: <br>
          - 🟩 Green squares: Correct letter and position<br>
          - 🟨 Yellow squares: Correct letter, wrong position<br>
          - Gray squares (keyboard): Letter not in the name<br><br>
          The keyboard marks used letters to avoid duplicates. Win by guessing the player's name within 6 attempts.`,
    });

    // console.log([...new Set(words)].slice(80,223).sort(() => Math.random() - 0.5), "oa");

    var now = new Date();
    // var now = new Date('Jul 23, 2024 01:24:00')
    now.setHours(0, 0, 0, 0);


    var start = new Date(now.getFullYear(), 0, 0);
    var diff = now - start;
    var oneDay = 1000 * 60 * 60 * 24;
    var day = Math.floor(diff / oneDay)+1;

    // day = 312;
    // console.log(day)

    //This is starting from first day
    const selectedWord = ref();
    // if (day >= 312) {
    //   selectedWord.value = words[day - 312];
    // } else {
    //   selectedWord.value = words[day + 50];
    // }

    selectedWord.value = words[day];

    // const selectedWord = ref(words.sort(() => Math.random() - 0.5)[0]);
    // const selectedWord = ref("MESSI");

    let dayPrevious = Number(window.localStorage.getItem("b5-wordle-day"));

    if (dayPrevious != day) {
      window.localStorage.removeItem("b5-wordle-daily");
      window.localStorage.removeItem("b5-wordle-day");
    }

    document.addEventListener("DOMContentLoaded", () => {
      document.getElementById("infoButtonCircle").onclick = () =>
        (showInfo.value = true);
      document.getElementById("statsButtonCircle").onclick = () =>
        (showStats.value = true);
    });

    // const playerCount = {};
    // for (const player of words) {
    //   playerCount[player] = (playerCount[player] || 0) + 1;
    // }

    // for (const player in playerCount) {
    //   if (playerCount[player] >= 2) {
    //     console.log(`${player}: ${playerCount[player]} times`);
    //   }
    // }

    // window.localStorage.removeItem("b5-wordle-daily");

    const B5WordleDaily = ref({
      selectedPlayer: selectedWord,
      guesses: [],
      currentIndex: 1,
      guessedLetters: { correct: [""], hint: [""], wrong: [""] },
      gameStatus: ref("playing"),
    });

    const B5Wordle = ref({
      stats: {
        played: 0,
        wins: 0,
        currentStreak: 0,
        maxStreak: 0,
      },

      distribution: ref([0, 0, 0, 0, 0, 0]),
    });

    const initLocalStorage = () => {
      const storedB5Daily = window.localStorage.getItem("b5-wordle-daily");

      if (!storedB5Daily) {
        window.localStorage.setItem(
          "b5-wordle-daily",
          JSON.stringify(B5WordleDaily.value)
        );
      } else {
        B5WordleDaily.value = JSON.parse(storedB5Daily);
      }

      const storedB5 = window.localStorage.getItem("b5-wordle");

      if (!storedB5) {
        window.localStorage.setItem(
          "b5-wordle",
          JSON.stringify(B5Wordle.value)
        );
      } else {
        B5Wordle.value = JSON.parse(storedB5);
      }

      const storedDay = window.localStorage.getItem("b5-wordle-day");

      if (!storedDay) {
        window.localStorage.setItem("b5-wordle-day", day);
      }
    };

    initLocalStorage();

    const currentGuessedWord = ref(
      Array.from(
        { length: B5WordleDaily.value.selectedPlayer.length },
        () => null
      )
    );

    const saveData = () => {
      window.localStorage.setItem(
        "b5-wordle-daily",
        JSON.stringify(B5WordleDaily.value)
      );

      window.localStorage.setItem("b5-wordle", JSON.stringify(B5Wordle.value));
    };

    if (B5WordleDaily.value.gameStatus == "playing") {
      // showInfo.value = false;
    }

    // window.localStorage.removeItem('b5-wordle-daily')

    const keyPressed = ref("");

    const handleKeyDown = (event) => {
      if (B5WordleDaily.value.gameStatus == "playing") {
        if (/^[a-zA-Z]$/.test(event.key)) {
          keyPressed.value = event.key.toUpperCase();
          for (let i = 0; i < currentGuessedWord.value.length; i++) {
            if (currentGuessedWord.value[i] === null) {
              currentGuessedWord.value[i] = keyPressed.value;
              break;
            }
          }
        }

        if (event.key === "Backspace") {
          removeLetter();
        }

        if (event.key === "Enter") {
          guessWord();
        }
      }
    };

    const handleInput = (key) => {
      if (B5WordleDaily.value.gameStatus == "playing") {
        if (key == "{enter}") {
          guessWord();
        } else if (key == "{bksp}") {
          removeLetter();
        } else {
          for (let i = 0; i < currentGuessedWord.value.length; i++) {
            if (currentGuessedWord.value[i] === null) {
              currentGuessedWord.value[i] = key.toUpperCase();
              break;
            }
          }
        }
      }
    };

    const removeLetter = () => {
      const lastNonNullIndex = currentGuessedWord.value
        .slice()
        .reverse()
        .findIndex((element) => element !== null);
      if (lastNonNullIndex !== -1) {
        currentGuessedWord.value[
          currentGuessedWord.value.length - 1 - lastNonNullIndex
        ] = null;
      }
    };

    const guessWord = () => {
      if (
        currentGuessedWord.value.findIndex((element) => element == null) !== -1
      ) {
        message.value = "Please complete the full word";
      } else {
        message.value = "";
        compareWords();
      }
    };

    const compareWords = () => {
      let guessedWord = currentGuessedWord.value.map((letter) => ({
        lt: letter,
      }));
      let wordOfTheDay = B5WordleDaily.value.selectedPlayer
        .split("")
        .map((lt) => ({ lt }));

      for (let i = 0; i < guessedWord.length; i++) {
        for (let j = 0; j < wordOfTheDay.length; j++) {
          if (
            guessedWord[i].lt == wordOfTheDay[j].lt &&
            guessedWord[i].result != "green" &&
            wordOfTheDay[j].result != "found"
          ) {
            if (i == j) {
              wordOfTheDay[j].result = "found";
              guessedWord[i].result = "green";
            }
          }
        }
      }

      for (let i = 0; i < guessedWord.length; i++) {
        for (let j = 0; j < wordOfTheDay.length; j++) {
          if (
            guessedWord[i].lt == wordOfTheDay[j].lt &&
            guessedWord[i].result != "green" &&
            wordOfTheDay[j].result != "found" &&
            wordOfTheDay[j].result != "hint"
          ) {
            guessedWord[i].result = "yellow";
            wordOfTheDay[j].result = "hint";
            break;
          }
        }
      }

      B5WordleDaily.value.guesses.push(guessedWord);

      for (let i = 0; i < guessedWord.length; i++) {
        let lett = guessedWord[i].lt.toLowerCase();

        if (guessedWord[i].result) {
          if (guessedWord[i].result == "green") {
            B5WordleDaily.value.guessedLetters.correct[0] += " " + lett;
            B5WordleDaily.value.guessedLetters.hint[0] =
              B5WordleDaily.value.guessedLetters.hint[0].split(lett).join("");
          } else {
            if (!B5WordleDaily.value.guessedLetters.correct[0].includes(lett)) {
              B5WordleDaily.value.guessedLetters.hint[0] += " " + lett;
            }
          }
        } else {
          if (
            !B5WordleDaily.value.guessedLetters.correct[0].includes(lett) &&
            !B5WordleDaily.value.guessedLetters.hint[0].includes(lett)
          ) {
            B5WordleDaily.value.guessedLetters.wrong[0] += " " + lett;
          }
        }
      }

      checkGameEnding();
      saveData();
    };

    const checkGameEnding = () => {
      let guesses = B5WordleDaily.value.guesses;

      let correctLetters = guesses[guesses.length - 1].filter(
        (letter) => letter.result == "green"
      ).length;

      let wordLength = guesses[guesses.length - 1].length;

      if (correctLetters == wordLength) {
        endGame("win");
      } else {
        if (B5WordleDaily.value.currentIndex < 6) {
          B5WordleDaily.value.currentIndex++;
        } else {
          endGame("loss");
        }
      }

      currentGuessedWord.value = Array.from(
        { length: B5WordleDaily.value.selectedPlayer.length },
        () => null
      );
    };

    const endGame = (result) => {
      if (result == "win") {
        B5WordleDaily.value.gameStatus = "won";
        B5Wordle.value.stats.wins++;
        B5Wordle.value.stats.currentStreak++;
        B5Wordle.value.distribution[B5WordleDaily.value.currentIndex - 1]++;
        if (
          B5Wordle.value.stats.currentStreak > B5Wordle.value.stats.maxStreak
        ) {
          B5Wordle.value.stats.maxStreak = B5Wordle.value.stats.currentStreak;
        }
      } else if (result == "loss") {
        B5Wordle.value.stats.currentStreak = 0;
        B5WordleDaily.value.gameStatus = "lost";
      }
      B5Wordle.value.stats.played++;

      setTimeout(() => {
        showStats.value = true;
      }, 2000);

      saveData();
    };

    onMounted(() => {
      window.addEventListener("keydown", handleKeyDown);
      document.title = "Basketball5 Wordle - Play NBA Wordle";
    });

    onUnmounted(() => {
      window.removeEventListener("keydown", handleKeyDown);
    });

    return {
      currentGuessedWord,
      message,

      handleInput,

      B5WordleDaily,

      showInfo,
      showStats,
      day,
      rules,
    };
  },
};
</script>

<style scoped>
* {
  /* color: crimson; */
}

.letter-box {
  display: inline-block;
}

.wordle-box {
  height: 45px;
  width: 45px;
  margin: 5px;
  text-transform: uppercase;
  text-align: center;
  font-size: 30px;
  color: black;
  font-weight: 600;
  background-color: white;
}

.wordle-box2 {
  height: 30px;
  width: 30px;
  color: black;
  margin: 5px;
}
</style>