<template>
    <div style="position: relative">
      <div class="desktop">
          <div
          style="
            margin: 10px auto 10px auto;
            text-align: center;
            width: 100%;
            overflow: hidden;
            min-height: 600px;
            max-width: 300px; 
          "
          class="right"
        >
          <div id="snack_mpu"> 
          </div>
        </div>
  
        <div
          style="
            margin: 10px auto 10px auto;
            text-align: center;
            width: 100%;
            overflow: hidden;
            min-height: 600px;
            max-width: 300px; 
          "
          class="left"
        >
          <div id="snack_dmpu">  
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { onBeforeMount } from "vue";
  export default {
    setup() {
      onBeforeMount(() => {
        // const script = document.createElement("script");
        // script.id = "snack_ads";
        // script.src =
        //   "https://cdn-header-bidding.snack-media.com/assets/js/snack-loader/3239";
        // script.crossOrigin = "anonymous";
  
        // // Append the script to the head or body
        // document.head.appendChild(script); // You can also use document.body.appendChild(script); if you prefer
  
        // Optionally, you can log a message to confirm that the script has been added
        // console.log('Snack Ads script added to the document.');
      });
  
      return {};
    },
  
    mounted() {
      this.adsenseAddLoad();
    },
  
    methods: {
      adsenseAddLoad() {
        let inlineScript = document.createElement("script");
        inlineScript.type = "text/javascript";
        inlineScript.text = "(adsbygoogle = window.adsbygoogle || []).push({});";
        document.getElementsByTagName("body")[0].appendChild(inlineScript);
      },
    },
  };
  </script>
  
  <style scoped>
  .desktop {
    /* position: absolute;
      left: 0;
      right: 0;
      top: 0;
      display: flex;
      justify-content: space-evenly; */
  }
  .desktop .left {
    position: absolute;
    left: 20px;
    top: 20px;
  }
  
  .desktop .right {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  
  @media screen and (max-width: 1240px) {
    .desktop {
      display: none;
    }
  }
  </style>
  